<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-2xl text-white">About</div>
          </a>
        </li>

        <li class="icon" @click="getData">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li>
          <a href="#">
            <span
              class="mx-2 text-white cursor-pointer"
              @click="showBackendEnvironmentData"
            >
              {{ `Version ${version} - ${build}` }}
            </span>
          </a>
        </li>
      </ul>
    </nav>

    <div v-if="!loading">
      <div v-if="selectedVersion" class="p-5">
        <div class="flex justify-between text-xl">
          <div class="flex">
            <div class="flex justify-end mt-2">
              <button
                @click="handleGetNextVersion"
                class="btn-icon"
                :class="{ hidden: selectedVersionIndex === versions.length }"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <h3 class="font-bold text-blue-500 mx-2">
                {{ `Version No. ${selectedVersion.name} ` }}
              </h3>
              <button
                @click="handleGetPreviousVersion"
                class="btn-icon"
                :class="{ hidden: selectedVersionIndex === 0 }"
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          <button @click="handleHideSelectedVersion" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="text-blue-600 ml-5">
          {{ `Released on ${formatDate(selectedVersion.releaseDate, "UTC")}` }}
        </div>

        <div class="frame">
          <div v-if="selectedVersion.improvements.length">
            <div class="text-xl text-green-500">Improvements:</div>
            <ul class="text-base">
              <li
                v-for="(improvement, i) in selectedVersion.improvements"
                :key="i"
              >
                {{ improvement.description }}
              </li>
            </ul>
          </div>

          <div v-if="selectedVersion.fixes.length">
            <h3 class="text-xl text-orange-500">Fixes:</h3>
            <ul class="text-base">
              <li v-for="(fix, i) in selectedVersion.fixes" :key="i">
                {{ fix.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- Data Table -->
        <div class="relative overflow-x-auto">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('name')"
                >
                  Version
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('releaseDate')"
                >
                  Release date
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('improvements')"
                >
                  Improvements
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('fixes')"
                >
                  Fixes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="version in versions"
                :key="version._id"
                @click="handleSeeVersion(version)"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ version.name }}
                </td>

                <td class="px-6 py-2">
                  {{ formatDate(version.releaseDate, "UTC") }}
                </td>
                <td class="px-6 py-2">{{ version.improvements.length }}</td>
                <td class="px-6 py-2">{{ version.fixes.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "about",
  mixins: [mixin],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      loading: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      selectedVersion: null,
      selectedVersionIndex: 0,
      versions: [],
    };
  },

  created() {
    this.getData();
  },

  computed: {},

  methods: {
    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.versions, key);

      if (sortedAsc) {
        this.versions.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.versions.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
    handleSeeVersion(v) {
      const i = this.versions.findIndex((item) => {
        return item.name === v.name;
      });

      this.selectedVersion = { ...v };
      this.selectedVersionIndex = i;
    },
    handleHideSelectedVersion() {
      this.selectedVersion = null;
    },
    handleGetPreviousVersion() {
      this.selectedVersionIndex -= 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    handleGetNextVersion() {
      this.selectedVersionIndex += 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    async showBackendEnvironmentData() {
      try {
        const url = api.defaults.baseURL;
        const res = await api.get(url.substring(0, url.length - 7));

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `${res.data.version} - ${res.data.status}`;
        this.snackbarVisible = true;
      } catch (error) {
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }

      try {
        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "Home" });
          return;
        }

        const res = await api.get("/versions", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "Home" });
          }, 3000);

          return;
        }

        this.versions = res.data.versions;

        this.loading = false;
      } catch (error) {
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
#scrollable-vertical-frame {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}
</style>

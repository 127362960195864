<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Planning</div>
          </a>
        </li>

        <li @click="handleToggleTimeZone" class="icon">
          <a href="#">
            <span class="tooltip">Change Time Zone</span>
            <span>
              <i
                :class="[
                  'fa-solid fa-clock',
                  localTime ? 'text-red-500' : 'text-white',
                ]"
              ></i>
            </span>
          </a>
        </li>

        <li>
          <a href="#"> </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- UNABLE TO ADD DIALOG -->
    <r-modal v-if="showUnableToAddDialog" @close="handleHideUnableToAddDialog">
      <div v-if="showUnableToAddDialog" class="p-5 bg-red-200">
        <div class="flex justify-between mb-2">
          <div class="flex items-center">
            <i class="fa-solid fa-exclamation-triangle"></i>
            <div class="text-2xl font-bold ml-2">Unable to Add Schedule</div>
          </div>
          <button @click="handleHideUnableToAddDialog" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          v-if="conflicts.length"
          class="w-full mb-5 border-b pb-3 border-gray-400"
        >
          <div class="text-lg font-bold px-1 mb-1">Schedule Conflicts</div>
          <ul>
            <li v-for="conflict in conflicts" :key="conflict._id">
              {{ formatConflictMessage(conflict) }}
            </li>
          </ul>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleHideUnableToAddDialog"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div v-else>
      <div class="px-5 py-1.5 border-b" :class="{ 'edit-mode': editMode }">
        <div class="flex gap-3 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Customer</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedCustomer"
            >
              <option
                v-for="item in customerOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Aircraft</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft"
            >
              <option
                v-for="item in aircraftOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.registration }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Flight Number</p>

            <input
              class="block rounded-md border-0 max-w-[100px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !isValidCallsign }"
              v-model="flightNumber"
              type="text"
              maxlength="10"
              name="flightNumber"
              id="flightNumber"
              placeholder="Flight No."
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">From</p>

            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedOrigin }"
              v-model="originIata"
              type="text"
              maxlength="3"
              name="originIata"
              id="originIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">To</p>
            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedDestination }"
              v-model="destinationIata"
              type="text"
              maxlength="3"
              name="destinationIata"
              id="destinationIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <div class="flex">
              <div>
                <p class="ml-2 text-sm text-gray-500">
                  {{ computedStartDateLabel }}
                </p>
                <input
                  class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startDateString"
                  type="date"
                />
              </div>
              <div>
                <p class="ml-2 text-sm text-gray-500">
                  {{ computedStarTimeLabel }}
                </p>
                <input
                  class="ml-1 block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startTimeString"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="flex gap-2">
              <!-- <div>
                <p class="ml-2 text-sm text-gray-500">Days</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="180"
                  v-model="days"
                />
              </div> -->
              <div>
                <p class="ml-2 text-sm text-gray-500">Hours</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="23"
                  v-model="hours"
                />
              </div>

              <div>
                <p class="ml-2 text-sm text-gray-500">Minutes</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="59"
                  v-model="minutes"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <p class="ml-2 text-sm text-gray-500">Remarks</p>
          <div>
            <textarea
              v-model="remarks"
              rows="2"
              maxlength="400"
              siz
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-0.5 px-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div>
            <p class="ml-5 text-sm text-gray-500">Hidden</p>

            <div class="flex mb-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center ml-3">
                <Switch
                  v-model="hidden"
                  :class="[
                    hidden ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      hidden ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-3">
                Flight will not appear on public searches.
              </div>
            </div>
          </div>

          <button
            @click="handleAddEvent"
            class="ml-1 mt-7 btn-small bg-blue-500 text-white"
            :class="{ disabled: !isValid }"
          >
            Add
          </button>
        </div>
      </div>
      <div class="px-5 py-1.5">
        <div v-if="schedule.length">
          <div v-for="item in schedule" :key="item.aircraft._id">
            <div class="border border-gray-500 rounded-md p-2 my-2">
              <div class="flex justify-between items-center px-2 py-1 mb-2">
                <div class="text-2xl font-bold text-blue-700">
                  {{ item.aircraft.registration }}
                </div>
                <button
                  class="btn-icon"
                  @click="handleToggleCardExpanded(item.aircraft.registration)"
                >
                  <i
                    :class="{
                      'fa-solid fa-chevron-down': !expandedCards.includes(
                        item.aircraft.registration
                      ),
                      'fa-solid fa-chevron-up': expandedCards.includes(
                        item.aircraft.registration
                      ),
                    }"
                  ></i>
                </button>
              </div>

              <div v-if="expandedCards.includes(item.aircraft.registration)">
                <div
                  v-for="(event, index) in item.events"
                  :key="event.tempId"
                  class="event-card"
                  :class="{ private: event.hidden }"
                >
                  <div class="flex justify-between items-center">
                    <div class="flex gap-2">
                      <div class="text-lg font-bold">
                        {{ `${index + 1} - ` }}
                      </div>

                      <div class="text-lg font-bold">
                        {{ event.flightNumber }}
                      </div>
                      <div class="text-lg font-bold">
                        {{ event.origin.iata }}-{{ event.destination.iata }}
                      </div>
                      <div class="text-lg font-bold">
                        {{
                          formatDateTime(event._start, event.origin.timeZone)
                        }}
                        -
                        {{ formatTime(event._end, event.destination.timeZone) }}
                      </div>
                      <div class="text-lg font-bold">
                        {{ event.duration.text }}
                      </div>
                    </div>
                    <div class="flex gap-2">
                      <button
                        @click="handleEditEvent(item, event)"
                        class="btn-icon"
                      >
                        <i class="fa-solid fa-edit text-gray-500"></i>
                      </button>
                      <button
                        @click="handleDeleteEvent(item, event)"
                        class="btn-icon"
                      >
                        <i class="fa-solid fa-trash-can text-gray-500"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-5">
                  <button
                    @click="handleSaveSchedule(item)"
                    class="btn bg-blue-500 text-white"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { nanoid } from "nanoid";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
  SwitchGroup,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "planning",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
    SwitchGroup,
  },

  data() {
    return {
      loading: false,

      editMode: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //-------------------------------Unable to Add
      conflicts: [],

      //---------------------------------------Dialogs
      showUnableToAddDialog: false,

      //----------------------Aircraft List
      schedule: [],
      expandedCards: [],

      //-----------------------Aircraft Event

      selectedStatus: null,
      selectedCustomer: null,
      selectedAircraft: null,
      flightNumber: "",
      hidden: false,
      originIata: "",
      destinationIata: "",
      startDateString: "",
      startTimeString: "",
      startMillis: 0,
      endMillis: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      remarks: "",
      minimumDuration: 20,
      maximumDuration: 8 * 60, // 8 hours - Applies to Aircraft Operational
      previousEnd: null,
      previousDestinationIata: "",
    };
  },

  created() {
    let i = this.customerOptions.findIndex((c) => {
      return c.name === "WORLD ATLANTIC AIRLINES";
    });

    if (i >= 0) {
      this.selectedCustomer = { ...this.customerOptions[i] };
    } else {
      this.selectedCustomer = { ...this.customerOptions[0] };
    }

    this.flightNumber = `${this.selectedCustomer.callsign}-`;

    i = this.aircraftOptions.findIndex((a) => {
      return a.registration === "N801WA";
    });

    if (i >= 0) {
      this.selectedAircraft = { ...this.aircraftOptions[i] };
    } else {
      this.selectedAircraft = { ...this.aircraftOptions[0] };
    }

    //--------------------------------------Start Date and Time
    this.startMillis = new Date().getTime();

    this.startDateString = new Date(this.startMillis)
      .toISOString()
      .substring(0, 10);
    this.startTimeString = new Date(this.startMillis)
      .toISOString()
      .substring(11, 16);

    this.days = 0;
    this.hours = 3;
    this.minutes = 0;

    this.originIata = "";
    this.destinationIata = "";
    this.hidden = false;

    this.remarks = "";
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    localTime() {
      return this.$store.state.localTime;
    },

    computedStartDateLabel() {
      if (this.localTime) {
        return "Date (Local)";
      } else {
        return "Date (UTC)";
      }
    },

    computedStarTimeLabel() {
      if (this.localTime) {
        return "Time (Local)";
      } else {
        return "Time (UTC)";
      }
    },

    customerOptions() {
      return this.$store.state.customers;
    },

    aircraftOptions() {
      return this.$store.state.aircraft;
    },

    airports() {
      return this.$store.state.airports;
    },

    computedOrigin() {
      if (this.originIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.originIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    computedDestination() {
      if (this.destinationIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.destinationIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    isValidCallsign() {
      return this.flightNumber.length > 4;
    },

    computedDuration() {
      if (isNaN(this.days) || isNaN(this.hours) || isNaN(this.minutes)) {
        return {
          isValid: false,
        };
      }

      const totalMinutes = this.days * 24 * 60 + this.hours * 60 + this.minutes;

      const d = Math.floor(totalMinutes / (24 * 60)); // Calculate full days
      const h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
      const m = totalMinutes % 60; // Calculate remaining minutes

      if (
        totalMinutes > this.selectedAircraft.rangeInMinutes ||
        totalMinutes < this.minimumDuration
      ) {
        return {
          isValid: false,
        };
      }

      return {
        totalMinutes,
        days: d,
        hours: h,
        minutes: m,
        text: `${h.toString().padStart(2, "0")}+${m
          .toString()
          .padStart(2, "0")}`,
        isValid: true,
      };
    },

    isValid() {
      let isValid =
        this.selectedCustomer &&
        this.selectedAircraft &&
        this.computedOrigin &&
        this.computedDuration.isValid &&
        this.isValidCallsign &&
        this.computedDestination;

      return isValid;
    },
  },

  methods: {
    handleToggleCardExpanded(registration) {
      const index = this.expandedCards.findIndex((i) => i === registration);

      if (index === -1) {
        this.expandedCards.push(registration);
      } else {
        this.expandedCards.splice(index, 1);
      }
    },

    handleToggleTimeZone() {
      const localTime = !this.localTime;
      this.$store.commit("updateLocalTime", localTime);
    },

    handleHideUnableToAddDialog() {
      this.showUnableToAddDialog = false;
    },

    formatConflictMessage(conflict) {
      const { flightNumber, aircraft, origin, destination, _start, _end } =
        conflict;

      return ` ${flightNumber} . ${aircraft.registration} . ${origin.iata}-${
        destination.iata
      } . ${this.formatDateTime(_start, origin.timeZone)} - ${this.formatTime(
        _end,
        destination.timeZone
      )}`;
    },

    async handleAddEvent() {
      if (!this.isValid) {
        return;
      }

      const origin = { ...this.computedOrigin };

      let _start = new Date(
        `${this.startDateString}T${this.startTimeString}:00.000+00:00`
      ).getTime();

      if (this.localTime) {
        function getUTCOffsetMinutes(dt, timeZone) {
          const _dt = new Date(dt);

          // Convert the current UTC time to the target time zone
          const localTime = new Intl.DateTimeFormat("en-US", {
            timeZone,
            hourCycle: "h23",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).formatToParts(_dt);

          // Extract components
          const dateParts = {};
          localTime.forEach((part) => {
            if (part.type !== "literal") dateParts[part.type] = part.value;
          });

          // Create a date string in "YYYY-MM-DDTHH:mm:ss" format
          const targetDateStr = `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}Z`;

          // Get UTC and target timezone offsets in minutes
          const targetDate = new Date(targetDateStr);
          const utcOffsetMinutes =
            (targetDate.getTime() - _dt.getTime()) / 60000;

          return utcOffsetMinutes;
        }

        const offsetMinutes = getUTCOffsetMinutes(_start, origin.timeZone);

        if (offsetMinutes > 0) {
          _start = _start + offsetMinutes * 60 * 1000;
        } else {
          _start = _start - offsetMinutes * 60 * 1000;
        }
      }

      let event;

      const _end =
        _start +
        this.days * 24 * 60 * 60 * 1000 +
        this.hours * 60 * 60 * 1000 +
        this.minutes * 60 * 1000;

      this.previousEnd = _end;

      event = {
        tempId: nanoid(),
        type: "Flight",
        aircraft: { ...this.selectedAircraft },
        customer: { ...this.selectedCustomer },
        flightNumber: this.flightNumber,
        origin,
        destination: { ...this.computedDestination },
        _start,
        _end,
        duration: { ...this.computedDuration },
        hidden: this.hidden ? true : false,
        remarks: this.remarks ? this.remarks : "",
      };

      this.previousDestinationIata = this.computedDestination.iata;

      if (this.schedule.length === 0) {
        this.schedule.push({
          aircraft: { ...this.selectedAircraft },
          events: [event],
        });
      } else {
        const i = this.schedule.findIndex((a) => {
          return a.aircraft._id === this.selectedAircraft._id;
        });

        if (i >= 0) {
          this.schedule[i].events.push(event);
        } else {
          this.schedule.push({
            aircraft: { ...this.selectedAircraft },
            events: [event],
          });
        }
      }

      this.schedule.sort((a, b) => {
        return a.aircraft.registration.localeCompare(b.aircraft.registration);
      });

      this.schedule.forEach((a) => {
        a.events.sort((a, b) => {
          return a._start - b._start;
        });
      });

      if (!this.expandedCards.includes(this.selectedAircraft.registration)) {
        this.expandedCards.push(this.selectedAircraft.registration);
      }

      this.handleInitNextEvent();
    },

    handleInitNextEvent() {
      const groundTime = 2 * 60 * 60 * 1000;
      const start = this.previousEnd + groundTime;

      this.startDateString = new Date(start).toISOString().substring(0, 10);
      this.startTimeString = new Date(start).toISOString().substring(11, 16);
      this.originIata = this.destinationIata;

      this.remarks = "";
      this.destinationIata = "";
      this.editMode = false;
    },

    handleDeleteEvent(item, event) {
      const index = this.schedule.findIndex((a) => {
        return a.aircraft._id === item.aircraft._id;
      });

      if (index >= 0) {
        const i = this.schedule[index].events.findIndex((e) => {
          return e.tempId === event.tempId;
        });

        if (i >= 0) {
          this.schedule[index].events.splice(i, 1);
        }

        if (this.schedule[index].events.length === 0) {
          this.schedule.splice(index, 1);
        } else {
          this.schedule[index].events.sort((a, b) => {
            return a._start - b._start;
          });
        } // Sort events by start Time
      }
    },

    handleEditEvent(item, event) {
      this.selectedCustomer = { ...event.customer };
      this.selectedAircraft = { ...event.aircraft };
      this.startDateString = new Date(event._start)
        .toISOString()
        .substring(0, 10);
      this.startTimeString = new Date(event._start)
        .toISOString()
        .substring(11, 16);

      this.durationDays = event.duration.days;
      this.durationHours = event.duration.hours;
      this.durationMinutes = event.duration.minutes;
      this.originIata = event.origin.iata;
      this.remarks = event.remarks ? event.remarks : "";
      this.flightNumber = event.flightNumber;
      this.destinationIata = event.destination.iata;
      this.hidden = event.hidden ? true : false;

      const index = this.schedule.findIndex((a) => {
        return a.aircraft._id === item.aircraft._id;
      });

      if (index >= 0) {
        const i = this.schedule[index].events.findIndex((e) => {
          return e.tempId === event.tempId;
        });

        if (i >= 0) {
          this.schedule[index].events.splice(i, 1);
        }

        if (this.schedule[index].events.length === 0) {
          this.schedule.splice(index, 1);
        } else {
          this.schedule[index].events.sort((a, b) => {
            return a._start - b._start;
          });
        } // Sort events by start time
      }

      this.editMode = true;
    },

    async handleSaveSchedule(item) {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "Home" });
        return;
      }

      const firstEvent = item.events[0];
      const lastEvent = item.events[item.events.length - 1];
      const _start = firstEvent._start;
      const _end = lastEvent._end;

      try {
        this.conflicts = [];
        let res;

        res = await api.post(
          `/aircraft-events/planning/precheck`,
          {
            aircraftSchedule: {
              aircraftId: firstEvent.aircraft._id,
              _start,
              _end,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "Home" });
          }, 3000);

          return;
        }

        this.conflicts = res.data.conflicts;

        if (this.conflicts.length) {
          this.loading = false;
          this.showUnableToAddDialog = true;
          return;
        }

        function formatEvents() {
          let events = [];

          //--------------Format Crewmembers
          const crewmembers = [
            {
              seat: "CAPT",
              duty: "Captain",
              required: true,
            },
            {
              seat: "FO",
              duty: "First Officer",
              required: true,
            },
            {
              seat: "ACM-1",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "FA-A",
              duty: "Flight Attendant A",
              required: true,
            },
            {
              seat: "FA-B",
              duty: "Flight Attendant B",
              required: true,
            },
            {
              seat: "FA-C",
              duty: "Flight Attendant ",
              required: true,
            },
            {
              seat: "FA-D",
              duty: "Flight Attendant ",
              required: true,
            },
            {
              seat: "FA-E",
              duty: "Flight Attendant ",
              required: false,
            },
            {
              seat: "FA-F",
              duty: "Flight Attendant ",
              required: false,
            },
            {
              seat: "GSC",
              duty: "Ground Security Coordinator",
              required: false,
            },
            {
              seat: "MX",
              duty: "Flight Mechanic",
              required: false,
            },
            {
              seat: "DH-1",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "DH-2",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "DH-3",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "DH-4",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "DH-5",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "DH-6",
              duty: "Deadhead",
              required: false,
            },
            {
              seat: "ACM-2",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "ACM-3",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "ACM-4",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "ACM-5",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "ACM-6",
              duty: "Additional Crewmember",
              required: false,
            },
            {
              seat: "ACM-7",
              duty: "Additional Crewmember",
              required: false,
            },
          ];

          //---------------Format each event for database
          item.events.forEach((event) => {
            events.push({
              type: event.type,
              status: "SCHEDULED",
              flightRules: "Part 117",
              unscheduledEvent: "None",
              needsUsCrew: false,
              crewmembers,
              fuelTickets: [],
              departureFuel: 0,
              arrivalFuel: 0,
              delays: [],
              bookedPaxAdults: 0,
              bookedPaxChildren: 0,
              bookedPaxInfants: 0,
              actualPaxAdults: 0,
              actualPaxChildren: 0,
              actualPaxInfants: 0,
              noTakeoff: 0,
              deiced: 0,
              aircraftId: event.aircraft._id,
              customerId: event.customer._id,
              flightNumber: event.flightNumber.toUpperCase(),
              hidden: event.hidden ? true : false,
              originId: event.origin._id,
              destinationId: event.destination._id,
              plannedDestinationId: event.destination._id,
              _std: new Date(event._start),
              _sta: new Date(event._end),
              _start: new Date(event._start),
              _end: new Date(event._end),
              parkingDeparture: "",
              parkingArrival: "",
              duration: event.duration,
              remarks: event.remarks ? event.remarks : null,
            });
          });

          return events;
        }

        const formattedEvents = formatEvents();

        res = await api.post(
          `/aircraft-events/planning`,
          {
            events: formattedEvents,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "Home" });
          }, 3000);
        }

        this.schedule = this.schedule.filter((a) => {
          return a.aircraft._id !== item.aircraft._id;
        });

        this.snackbarColor = "var(--green)";
        this.snackbarText = "Schedule Saved";
        this.snackbarVisible = true;

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "Home" });
        }, 3000);
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}

.event-card {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 1px;
  background-color: #93c5fd;
  color: black;
  cursor: pointer;
}

.footer {
  color: #fff;
  font-size: 1.3rem;
  margin-top: 5px;
  padding: 0 5px;
  grid-column: 1/13;
}

.warning {
  background-color: red;
  color: white;
}

.is-invalid {
  background-color: #fef08a;
}

.edit-mode {
  background-color: #bfdbfe;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aircraft-operational {
  background-color: #f5d0a9;
}

.fa-exclamation-triangle {
  color: var(--red);
  font-size: 2rem;
}

.private {
  border-left: 4px solid red;
}
</style>

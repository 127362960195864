<template>
  <div
    class="flex flex-col h-screen justify-between bg-cover bg-center bg-[url(../assets/customer_logo_large.png)] lg:bg-[url(../assets/home_page.jpg)]"
  >
    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <div class="content">
      <header class="text-xl bg-white/60">
        <div v-if="auth">
          <div class="flex justify-between mb-2">
            <div class="customer-banner"></div>
            <div class="app-banner"></div>
          </div>

          <div class="flex justify-between">
            <div class="pl-3">
              <span>{{ `Logged in as:` }}</span>
              <span class="font-black text-lg ml-2">
                {{ `${user.surname}, ${user.givenName}` }}
              </span>
              <div class="text-gray-500">
                {{ `Security Group: ${user.securityGroup.name}` }}
              </div>
            </div>

            <button
              v-if="auth && user"
              @click="handleLogout()"
              class="ml-1 mt-7 btn-small bg-red-500 text-white opacity-100"
            >
              Logout
            </button>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between mb-3 opacity-100">
            <div class="customer-banner"></div>
            <div class="app-banner"></div>
          </div>

          <div class="flex justify-center mt-3">
            <div class="border border-white rounded-md p-5">
              <div>
                <form @submit.prevent>
                  <div class="mb-3">
                    <p class="text-sm text-gray-500">Employee Number</p>
                    <input
                      id="employeeNumber"
                      name="employeeNumber "
                      class="block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="50"
                      v-model="employeeNumber"
                      placeholder="Emp. Number"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Password</p>

                    <div class="flex gap-1 items-center">
                      <input
                        id="password"
                        name="password"
                        class="block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        :type="hide ? 'password' : 'text'"
                        maxlength="50"
                        v-model="password"
                        placeholder="Password"
                        autocomplete="password"
                      />
                      <i
                        @click="hide = !hide"
                        class="cursor-pointer text-blue-800"
                        :class="{
                          'fa-solid fa-eye': !hide,
                          'fa-solid fa-eye-slash': hide,
                        }"
                      ></i>
                    </div>
                  </div>

                  <button
                    class="btn-text text-gray-500 text-sm"
                    @click="handleForgotPassword"
                  >
                    Forgot Password?
                  </button>

                  <div class="flex justify-end mt-5">
                    <div
                      @click="handleLogin"
                      class="w-full rounded-md bg-green-600 px-2.5 py-1.5 text-sm text-white text-center shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 cursor-pointer"
                      :class="{ disabled: !employeeNumber || !password }"
                    >
                      Login
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="text-end text-sm text-gray-500">
            {{ `Version: ${version}-${build}` }}
          </div>
        </div>
      </header>

      <div v-if="auth" class="flex flex-wrap">
        <!-- Flight Board -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToFlightBoard"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-plane text-blue-500"
            ></i>
            <span>Flight Board</span>
          </div>
        </div>

        <!-- Planning -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToPlanning"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-calendar text-red-500"
            ></i>
            <span>Planning</span>
          </div>
        </div>

        <!-- Customers -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToCustomers"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-people text-purple-500"
            ></i>
            <span>Customers</span>
          </div>
        </div>

        <!-- Employees Board -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToEmployeesBoard"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-person-walking-luggage text-orange-600"
            ></i>
            <span>Assignments</span>
          </div>
        </div>

        <!-- Training Records -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToTrainingRecords"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-clipboard text-yellow-800"
            ></i>
            <span>Training Records</span>
          </div>
        </div>

        <!-- Airports -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToAirports"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-tower-control text-blue-900"
            ></i>
            <span>Airports</span>
          </div>
        </div>

        <!-- Administration -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToAdministration"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-user-group text-blue-700"
            ></i>
            <span>Administration</span>
          </div>
        </div>

        <!-- Security -->
        <!-- <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToSecurityGroups"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-shield-quartered text-red-500"
            ></i>
            <span>Security Groups</span>
          </div>
        </div> -->

        <!-- Airports -->
        <!-- <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToAirports"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-tower-control text-blue-500"
            ></i>
            <span>Airports</span>
          </div>
        </div> -->

        <!-- Aircraft -->
        <!-- <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToAircraft"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-regular fa-plane-tail text-blue-900"
            ></i>
            <span>Aircraft</span>
          </div>
        </div> -->

        <!-- My Flight Times -->
        <!-- <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          @click="handleNavigateToMyApp"
        >
          <div class="title txt-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-user text-green-600"
            ></i>
            <span>My Flight Times</span>
          </div>
        </div> -->

        <!-- About -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="auth"
          @click="handleNavigateToAbout"
        >
          <div class="title">
            <div
              class="bg-cover bg-center bg-[url(../assets/customer_logo.png)] h-16 w-16"
            ></div>
            <div>
              <div class="txt-lg">About Flight Times</div>
              <div class="txt-sm">
                {{ `${version}-${build}` }}
              </div>
            </div>
          </div>
        </div>

        <!-- Manual -->
        <div
          class="tile w-full md:w-1/2 lg:w-1/4 xl:w-1/6"
          v-if="user"
          @click="handleShowManual"
        >
          <div class="title text-lg lg:text-xl">
            <i
              class="txt-[32px] lg:text-[48px] fa-solid fa-book text-blue-500"
            ></i>
            <span>Manuals</span>
          </div>
        </div>
      </div>
    </div>
    <footer class="text-sm lg:text-base">
      {{ disclaimer }}
    </footer>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSpinner from "../components/RSpinner.vue";
import RSnackbar from "../components/RSnackbar.vue";

export default {
  name: "Home",
  mixins: [mixin],
  components: { RSpinner, RSnackbar },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      employeeNumber: "", //Deploy
      password: "", //Deploy
      hide: true,
      disclaimer: `The contents of this website are considered Security Sensitive information that is controlled under the provisions of 49 CFR parts 15 and 1520. Flight schedule information and other related station and aircraft details are made available for the convenience of World Atlantic Airlines and Vendor Staff. World Atlantic Airlines cannot and does not guarantee that all information is current, complete or totally accurate at any given time. While we strive to provide complete, accurate and near real-time flight and schedule information, reliance on this software and the flight details herein is solely at the users own risk and World Atlantic Airlines disclaims any warranty or indemnity involved with the use of the software, whether express or implied.`,
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },

    user() {
      return this.$store.state.user;
    },

    enableFlightBoard() {
      if (!this.auth) {
        return false;
      }

      return true;
    },

    enableEmployeesBoard() {
      if (!this.auth) {
        return false;
      }

      return false;
    },

    enableSecurityGroups() {
      if (!this.auth) {
        return false;
      }

      return false;
    },

    enableMyApp() {
      if (!this.auth) {
        return false;
      }

      return false;
    },

    enableAirports() {
      if (!this.auth) {
        return false;
      }

      return false;
    },

    enableAircraft() {
      if (!this.auth) {
        return false;
      }

      return false;
    },
  },
  methods: {
    async handleForgotPassword() {
      if (!this.employeeNumber) {
        this.snackbarColor = "var(--red)";
        this.snackbarText = "Please enter your employee number.";
        this.snackbarVisible = true;
        return;
      }

      try {
        this.loading = true;

        const res = await api.post("/employees/forgot-password", {
          employeeNumber: this.employeeNumber,
          password: this.password,
        });

        this.employeeNumber = null;
        this.password = null;

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "var(--green)";
        this.snackbarText =
          "An email has been sent to your email address, please check your email and follow the instructions to reset your password. The reset link will expire in 10 minutes.";
        this.snackbarVisible = true;

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 10000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    //TODO upadate password
    async handleLogin() {
      if (!this.employeeNumber || !this.password) {
        return;
      }

      try {
        this.loading = true;

        const res = await api.post("/auth/login", {
          employeeNumber: this.employeeNumber,
          password: this.password,
        });

        this.employeeNumber = null;
        this.password = null;

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const user = res.data.employee;
        const accessToken = res.data.accessToken;
        const customers = res.data.customers;
        const aircraft = res.data.aircraft;
        const airports = res.data.airports;

        //Continue only if account exists in Active Directory

        const dt1 = new Date();
        const yy = dt1.getUTCFullYear();
        const mm = dt1.getUTCMonth();
        const dd = dt1.getUTCDate();

        const filterStart = new Date(
          Date.UTC(yy, mm, dd, 0, 0, 0, 0)
        ).getTime();

        this.$store.commit("updateDateFilter", {
          filterStart,
          numberOfDays: 7,
        });

        this.$store.commit("updateMonthFilter", {
          year: yy,
          month: mm,
          period: "Second",
        });

        this.$store.commit("updateCustomers", customers);
        this.$store.commit("updateAircraft", aircraft);
        this.$store.commit("updateAirports", airports);
        this.$store.commit("updateAuth", { accessToken, iat: Date.now() });
        this.$store.commit("updateUser", user);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "Home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.status;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleNavigateToFlightBoard() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "FlightBoard" });
    },

    async handleNavigateToPlanning() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "Planning" });
    },

    handleNavigateToEmployeesBoard() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "EmployeesBoard" });
    },

    async handleNavigateToCustomers() {
      //TEMP

      this.snackbarColor = "orange";
      this.snackbarText = "Under Development";
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);

      // try {
      //   this.loading = true;

      //   this.loading = false;

      //   if (res.status !== 200) {
      //     this.snackbarColor = "var(--red)";
      //     this.snackbarText = res.message;
      //     this.snackbarVisible = true;
      //     return;
      //   }

      //   const customers = res.data.customers;
      //   this.$store.commit("updateCustomers", customers);

      //   alert(`Customers: ${customers.length}`);
      // } catch (error) {
      //   this.loading = false;
      //   this.snackbarColor = "var(--red)";
      //   this.snackbarText = error;
      //   this.snackbarVisible = true;
      // }
    },

    handleNavigateToAdministration() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "Administration" });
    },

    handleNavigateToTrainingRecords() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "TrainingRecords" });
    },

    handleNavigateToAirports() {
      if (!this.auth) {
        return;
      }

      this.$router.push({ name: "Airports" });
    },
  },
};
</script>

<style scoped>
/* ------------------------------------------ Tiles */

.tile {
  box-sizing: border-box;
  background-color: aliceblue;
  border: 1px solid gray;
  /* border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  color: black;
  cursor: pointer;
}

.tile .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.tile span {
  margin-left: 10px;
}

.tile:hover {
  background-color: rgb(201, 199, 199);
}

.app-banner {
  background-image: url(../assets/app_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 150px;
  margin: 5px;
}

.customer-banner {
  background-image: url(../assets/customer_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 150px;
  margin: 5px;
}

.app-logo {
  background-image: url(../assets/flighttimes_logo_mini.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  width: 24px;
}

.content {
  margin: 10px 2%;
  padding: 10px;
  min-height: 500px;
}

header {
  margin: 10px 2%;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: black;
}

footer {
  padding: 10px;
  background-color: #002663;
  color: white;
}
</style>

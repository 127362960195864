export const mixin = {
  data() {
    return {
      jan2100: 4102444800000, //UNIX Epoch Jan 1st, 2100 @ 00:00 UTC
      jobTitles: [
        "Captain",
        "First Officer",
        "Senior Flight Attendant",
        "Flight Attendant",
        "Assitant Dispatcher",
        "Air Transportation Supervisor",
        "Base Coordinator",
        "Check Airman",
        "Chief Pilot",
        "Clerk",
        "Crew Scheduler",
        "Ground Instructor",
        "Ground Service Supervisor",
        "Manager of Dispatch",
        "Manager of In-Flight",
        "Manager of Stations",
        "Ramp Coordinator",
        "Supervisor of In-Flight",
        "Supervisor of Stations",
        "Charter Coordinator",
        "Director of Operations",
        "Manager of Crew Scheduling",
        "Dispatcher",
        "WAA Management",
        "WAA Staff",
        "Ground Security Coordinator",
        "Director of Safety & Security",
        "Manager of Safety Assurance",
        "Manager of Security",
        "Safety Auditor",
        "Security Coordinator",
        "In-Flight Mechanic",
        "Ground Mechanic",
        "Director of Maintenance",
        "Director of Quality",
        "Maintenance Rep.",
        "Manager of Avionics",
        "Manager of CASS",
        "Manager of Maintenance",
        "Manager of Planning",
        "Manager of Quality Control",
        "Manager of Records",
        "Manager of Tech Pubs",
        "QC Inspector",
        "Quality Assurance Auditor",
        "Records Clerk",
        "Supervisor of Records",
        "Tech Writer",
        "VP of Tech Ops",
        "Maintenance Controller",
        "FAA Inspector",
        "Crew Transportation",
        "Planning Rep",
        "Accounting Department",
        "HR Generalist",
        "Instructor",
        "Purchaser",
        "Stock Room Clerk",
        "Manager of Human resources",
        "Manager of SMS",
        "Chief Financial Officer",
        "Part 91 Pilot",
        "Manager of Stock Room",
        "Manager of Training",
        "Director of Flight Standards/Training",
        "VP Sales & Marketing",
        "President/CEO",
        "Technical Publications Administrator",
        "Director of Training",
        "Training Administrator",
        "Manager of Ramp",
        "Ramp supervisor",
        "Ramp Agent",
        "Manager of Customer Service",
        "Lead Customer Service",
        "Customer Service Agent",
        "Customer Service Representative",
        "Manager of Stations",
        "Stations Coordinator",
        "Certified Mechanic",
        "Non- Certified Mechanic",
        "Manager of Logistics",
        "Warehouse Manager",
        "Receiving/ Hangar manager",
        "Stockroom Clerk",
        "Director of Human Resources",
        "CASS Manager",
      ],
      seatOptions: [
        "CAPT",
        "FO",
        "ACM-1",
        "FA-A",
        "FA-B",
        "FA-C",
        "FA-D",
        "FA-E",
        "FA-F",
        "GSC",
        "MX",
        "DH-1",
        "DH-2",
        "DH-3",
        "DH-4",
        "DH-5",
        "DH-6",
        "ACM-2",
        "ACM-3",
        "ACM-4",
        "ACM-5",
        "ACM-6",
        "ACM-7",
      ],
      delayOptions: [
        {
          code: 0,
          category: "Airline Internal Code",
          alphaCode: "W0",
          descriptionShort: "00 - AIRCRAFT CHANGE",
          descriptionLong: "Aircraft Change",
        },
        {
          code: 1,
          category: "Airline Internal Code",
          alphaCode: "W1",
          descriptionShort: "01 - DELAY DUE TO TSA",
          descriptionLong: "Delay due to TSA",
        },
        {
          code: 2,
          category: "Airline Internal Code",
          alphaCode: "W2",
          descriptionShort: "02 - WORLD ATLANTIC SPECIFIC 3",
          descriptionLong: "Need description",
        },
        {
          code: 3,
          category: "Airline Internal Code",
          alphaCode: "W3",
          descriptionShort: "03 - WORLD ATLANTIC SPECIFIC 4",
          descriptionLong: "Need description",
        },
        {
          code: 4,
          category: "Airline Internal Code",
          alphaCode: "W4",
          descriptionShort: "04 - WORLD ATLANTIC SPECIFIC 5",
          descriptionLong: "Need description",
        },
        {
          code: 5,
          category: "Airline Internal Code",
          alphaCode: "W5",
          descriptionShort: "05 - WORLD ATLANTIC SPECIFIC 6",
          descriptionLong: "Need description",
        },
        {
          code: 6,
          category: "Other",
          alphaCode: "OA",
          descriptionShort: "06 - NO GATE/STAND AVAILABILITY",
          descriptionLong: "Due to own airline activity",
        },
        {
          code: 9,
          category: "Other",
          alphaCode: "SG",
          descriptionShort: "09 -SCHEDULED GROUND TIME",
          descriptionLong: "Less than declared minimum ground time",
        },
        {
          code: 11,
          category: "Passenger and Baggage",
          alphaCode: "PD",
          descriptionShort: "11- LATE CHECK-IN",
          descriptionLong: "Acceptance of passengers after deadline",
        },
        {
          code: 12,
          category: "Passenger and Baggage",
          alphaCode: "PL",
          descriptionShort: "12 - LATE CHECK-IN",
          descriptionLong: "Congestion in check-in area",
        },
        {
          code: 13,
          category: "Passenger and Baggage",
          alphaCode: "PE",
          descriptionShort: "13 - CHECK-IN ERROR",
          descriptionLong: "Error with passenger or baggage details",
        },
        {
          code: 14,
          category: "Passenger and Baggage",
          alphaCode: "PO",
          descriptionShort: "14 - OVERSALES",
          descriptionLong: "Booking errors",
        },
        {
          code: 15,
          category: "Passenger and Baggage",
          alphaCode: "PH",
          descriptionShort: "15 - BOARDING",
          descriptionLong:
            "Discrepancies and paging, missing checked-in passenger",
        },
        {
          code: 16,
          category: "Passenger and Baggage",
          alphaCode: "PS",
          descriptionShort: "16 - COMMERCIAL PUBLICITY/ PASSENGER CONVENIENCE",
          descriptionLong:
            "VIP, press, ground meals and missing personal items",
        },
        {
          code: 17,
          category: "Passenger and Baggage",
          alphaCode: "PC",
          descriptionShort: "17 - CATERING ORDER",
          descriptionLong: "Late or incorrect order given to supplier",
        },
        {
          code: 18,
          category: "Passenger and Baggage",
          alphaCode: "PB",
          descriptionShort: "18 - BAGGAGE PROCESSING",
          descriptionLong: "Late or incorrectly sorted baggage",
        },
        {
          code: 21,
          category: "Cargo and Mail",
          alphaCode: "CD",
          descriptionShort: "21 -DOCUMENTATION",
          descriptionLong: "Late or incorrect documentation for booked cargo",
        },
        {
          code: 22,
          category: "Cargo and Mail",
          alphaCode: "CP",
          descriptionShort: "22 - LATE POSITIONING",
          descriptionLong: "Late delivery of booked cargo to airport/aircraft",
        },
        {
          code: 23,
          category: "Cargo and Mail",
          alphaCode: "CC",
          descriptionShort: "23 - LATE ACCEPTANCE",
          descriptionLong: "Acceptance of cargo after deadline",
        },
        {
          code: 24,
          category: "Cargo and Mail",
          alphaCode: "CI",
          descriptionShort: "24 - INADEQUATE PACKING",
          descriptionLong: "Repackaging and / or re-labeling of booked cargo",
        },
        {
          code: 25,
          category: "Cargo and Mail",
          alphaCode: "CO",
          descriptionShort: "25 - OVERSALES",
          descriptionLong:
            "Booked load in excess of saleable load capacity (weight or volume), resulting in reloading or off-load",
        },
        {
          code: 26,
          category: "Cargo and Mail",
          alphaCode: "CU",
          descriptionShort: "26 - LATE PREPARATION",
          descriptionLong: "Late preparation in warehouse",
        },
        {
          code: 27,
          category: "Mail Only",
          alphaCode: "CE",
          descriptionShort: "27 - DOCUMENTATION, PACKING",
          descriptionLong: "Incomplete and / or inaccurate mail documentation",
        },
        {
          code: 28,
          category: "Mail Only",
          alphaCode: "CL",
          descriptionShort: "28 - LATE POSITIONING",
          descriptionLong: "Late delivery of mail to airport / aircraft",
        },
        {
          code: 29,
          category: "Mail Only",
          alphaCode: "CA",
          descriptionShort: "29 - LATE ACCEPTANCE",
          descriptionLong: "Acceptance of mail after deadline",
        },
        {
          code: 31,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GD",
          descriptionShort: "31 - LATE / INACCURATE AIRCRAFT DOCUMENTATION",
          descriptionLong:
            "Late or inaccurate mass and balance documentation, general declaration, passenger manifest",
        },
        {
          code: 32,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GL",
          descriptionShort: "32 - LOADING / UNLOADING",
          descriptionLong: "Bulky items, special load, lack loading staff",
        },
        {
          code: 33,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GE",
          descriptionShort: "33 - LOADING EQUIPMENT",
          descriptionLong:
            "Lack of and / or breakdown; lack of operating staff",
        },
        {
          code: 34,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GS",
          descriptionShort: "34 - SERVICING EQUIPMENT",
          descriptionLong:
            "Lack of and / or breakdown; lack of operating staff",
        },
        {
          code: 35,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GC",
          descriptionShort: "35 - AIRCRAFT CLEANING",
          descriptionLong: "Late completion of aircraft cleaning",
        },
        {
          code: 36,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GF",
          descriptionShort: "36 - FUELING / DEFUELING",
          descriptionLong: "Late delivery of fuel; excludes late request",
        },
        {
          code: 37,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GB",
          descriptionShort: "37 - CATERING",
          descriptionLong: "Late and / or incomplete delivery; late loading",
        },
        {
          code: 38,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GU",
          descriptionShort: "38 - ULD",
          descriptionLong: "Lack of and / or unserviceable ULD's or pallets",
        },
        {
          code: 39,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GT",
          descriptionShort: "39 - TECHNICAL EQUIPMENT",
          descriptionLong:
            "Lack and / or breakdown; lack of operating staff; includes GPU, air start, pushback tug, de-icing",
        },
        {
          code: 41,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TD",
          descriptionShort: "41 - TECHNICAL DEFECTS",
          descriptionLong: "Aircraft defects including items covered by MEL",
        },
        {
          code: 42,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TM",
          descriptionShort: "42 - SCHEDULED MAINTENANCE",
          descriptionLong: "Late release from maintenance",
        },
        {
          code: 43,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TN",
          descriptionShort: "43 - NON-SCHEDULED MAINTENANCE",
          descriptionLong:
            "Special checks and / or additional works beyond normal maintenance schedule",
        },
        {
          code: 44,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TS",
          descriptionShort: "44 - SPARES AND MAINTENANCE",
          descriptionLong:
            "Lack of spares, lack of and / or breakdown of specialist equipment required for defect rectification",
        },
        {
          code: 45,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TA",
          descriptionShort: "45 - AOG SPARES",
          descriptionLong:
            "Awaiting AOG spare(s) to be carried to another station",
        },
        {
          code: 46,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TC",
          descriptionShort: "46 - AIRCRAFT CHANGE",
          descriptionLong:
            "For technical reasons, e.g. a prolonged technical delay",
        },
        {
          code: 47,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TL",
          descriptionShort: "47 - STANDBY AIRCRAFT",
          descriptionLong: "Standby aircraft unavailable for technical reasons",
        },
        {
          code: 48,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TV",
          descriptionShort: "48 - CABIN CONFIGURATION",
          descriptionLong:
            "Scheduled cabin configuration and version adjustments",
        },
        {
          code: 51,
          category: "Damage to Aircraft",
          alphaCode: "DF",
          descriptionShort: "51 - DAMAGE DURING FLIGHT OPERATIONS",
          descriptionLong:
            "Bird or lightning strike, turbulence, heavy or overweight landing, collisions during taxiing",
        },
        {
          code: 52,
          category: "Damage to Aircraft",
          alphaCode: "DG",
          descriptionShort: "52 - DAMAGE DURING GROUND OPERATIONS",
          descriptionLong:
            "Collisions (other than taxiing), loading / offloading damage, towing, contamination, extreme weather conditions",
        },
        {
          code: 55,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "ED",
          descriptionShort: "55 - DEPARTURE CONTROL",
          descriptionLong:
            "Failure of automated systems, including check-in; load control systems producing mass and balance",
        },
        {
          code: 56,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EC",
          descriptionShort: "56 - CARGO PREPARATION DOCUMENTATION",
          descriptionLong:
            "Failure of documentation and / or load control systems covering cargo",
        },
        {
          code: 57,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EF",
          descriptionShort: "57 - FLIGHT PLANS",
          descriptionLong: "Failure of automated flight plan systems",
        },
        {
          code: 58,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EO",
          descriptionShort: "58 - OTHER AUTOMATED SYSTEM",
          descriptionLong: "Other automated system",
        },
        {
          code: 61,
          category: "Flight Operations and Crewing",
          alphaCode: "FP",
          descriptionShort: "61 - FLIGHT PLAN",
          descriptionLong: "Late completion of or change to flight plan",
        },
        {
          code: 62,
          category: "Flight Operations and Crewing",
          alphaCode: "FF",
          descriptionShort: "62 - OPERATIONAL REQUIREMENT",
          descriptionLong: "Late alteration to fuel or payload",
        },
        {
          code: 63,
          category: "Flight Operations and Crewing",
          alphaCode: "FT",
          descriptionShort: "63 - LATE CREW BOARDING OR DEPARTURE PROCEDURES",
          descriptionLong:
            "Late flight deck, or entire crew, other than standby; late completion of flight deck crew checks",
        },
        {
          code: 64,
          category: "Flight Operations and Crewing",
          alphaCode: "FS",
          descriptionShort: "64 - FLIGHT DECK CREW SHORTAGE",
          descriptionLong:
            "Sickness, awaiting standby, flight time limitations, valid visa, health documents, etc.",
        },
        {
          code: 65,
          category: "Flight Operations and Crewing",
          alphaCode: "FR",
          descriptionShort: "65 - FLIGHT DECK CREW SPECIAL REQUEST",
          descriptionLong: "Requests not within operational requirements",
        },
        {
          code: 66,
          category: "Flight Operations and Crewing",
          alphaCode: "FL",
          descriptionShort:
            "66 - LATE CABIN CREW BOARDING OR DEPARTURE PROCEDURES",
          descriptionLong:
            "Late cabin crew other than standby; late completion of cabin crew checks",
        },
        {
          code: 67,
          category: "Flight Operations and Crewing",
          alphaCode: "FC",
          descriptionShort: "67 - CABIN CREW SHORTAGE",
          descriptionLong:
            "Sickness, awaiting standby, flight time limitations, valid visa, health documents",
        },
        {
          code: 68,
          category: "Flight Operations and Crewing",
          alphaCode: "FA",
          descriptionShort: "68 - CABIN CREW ERROR OR SPECIAL REQUEST",
          descriptionLong: "Requests not within operational requirements",
        },
        {
          code: 69,
          category: "Flight Operations and Crewing",
          alphaCode: "FB",
          descriptionShort: "69 - CAPTAIN REQUEST FOR SECURITY CHECK",
          descriptionLong:
            "Extraordinary requests outside mandatory requirements",
        },
        {
          code: 71,
          category: "Weather",
          alphaCode: "WO",
          descriptionShort: "71 - DEPARTURE STATION",
          descriptionLong: "Below operating limits",
        },
        {
          code: 72,
          category: "Weather",
          alphaCode: "WT",
          descriptionShort: "72 - DESTINATION STATION",
          descriptionLong: "Below operating limits",
        },
        {
          code: 73,
          category: "Weather",
          alphaCode: "WR",
          descriptionShort: "73 - EN-ROUTE OR ALTERNATE",
          descriptionLong: "Below operating limits",
        },
        {
          code: 75,
          category: "Weather",
          alphaCode: "WI",
          descriptionShort: "75 - DE-ICING OF AIRCRAFT",
          descriptionLong:
            "Removal of ice and / or snow; excludes equipment  lack of or breakdown",
        },
        {
          code: 76,
          category: "Weather",
          alphaCode: "WS",
          descriptionShort:
            "76 - REMOVAL OF SNOW, ICE, WATER, AND SAND FROM AIRPORT",
          descriptionLong: "Runway, taxiway conditions",
        },
        {
          code: 77,
          category: "Weather",
          alphaCode: "WG",
          descriptionShort:
            "77 - GROUND HANDLING IMPAIRED BY ADVERSE WEATHER CONDITIONS",
          descriptionLong: "High winds, heavy rain, blizzards, monsoons etc.",
        },
        {
          code: 81,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AT",
          descriptionShort: "81 - ATFM DUE TO ATC EN-ROUTE DEMAND / CAPACITY",
          descriptionLong: "Standard demand / capacity problems",
        },
        {
          code: 82,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AX",
          descriptionShort: "82 - ATFM DUE TO ATC STAFF / EQUIPMENT ENROUTE",
          descriptionLong:
            "Reduced capacity caused by industrial action or staff shortage, equipment failure, military exercise or extraordinary demand due to capacity reduction in neighbouring area",
        },
        {
          code: 83,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AE",
          descriptionShort:
            "83 - ATFM DUE TO RESTRICTION AT DESTINATION AIRPORT",
          descriptionLong:
            "Airport and / or runway closed due to obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 84,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AW",
          descriptionShort: "84 - ATFM DUE TO WEATHER AT DESTINATION",
          descriptionLong:
            "Airport and / or runway closed due to weather at destination",
        },
        {
          code: 85,
          category: "Airport and Governmental Authorities",
          alphaCode: "AS",
          descriptionShort: "85 - MANDATORY SECURITY",
          descriptionLong: "Passengers, baggage, crew, etc.",
        },
        {
          code: 86,
          category: "Airport and Governmental Authorities",
          alphaCode: "AG",
          descriptionShort: "86 - IMMIGRATION, CUSTOMS, HEALTH",
          descriptionLong: "Passengers, crew",
        },
        {
          code: 87,
          category: "Airport and Governmental Authorities",
          alphaCode: "AF",
          descriptionShort: "87 - AIRPORT FACILITIES",
          descriptionLong:
            "Parking stands, ramp congestion, lighting, buildings, gate limitations etc.",
        },
        {
          code: 88,
          category: "Airport and Governmental Authorities",
          alphaCode: "AD",
          descriptionShort: "88 - RESTRICTIONS AT DESTINATION AIRPORT",
          descriptionLong:
            "Airport and / or runway closed due to obstruction industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 89,
          category: "Airport and Governmental Authorities",
          alphaCode: "AM",
          descriptionShort: "89 - RESTRICTIONS AT AIRPORT OF DEPARTURE",
          descriptionLong:
            "Including air traffic services, start-up and pushback, airport and / or runway closed due to obstruction or weather (restriction due to weather in case of ATFM only) industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 91,
          category: "Reactionary",
          alphaCode: "RL",
          descriptionShort: "91 - LOAD CONNECTION",
          descriptionLong: "Awaiting load from another flight",
        },
        {
          code: 92,
          category: "Reactionary",
          alphaCode: "RT",
          descriptionShort: "92 - THROUGH CHECK-IN ERROR",
          descriptionLong:
            "Passenger or baggage check-in error at originating station",
        },
        {
          code: 93,
          category: "Reactionary",
          alphaCode: "RA",
          descriptionShort: "93 - AIRCRAFT ROTATION",
          descriptionLong:
            "Late arrival of aircraft from another flight or previous sector",
        },
        {
          code: 94,
          category: "Reactionary",
          alphaCode: "RS",
          descriptionShort: "94 - CABIN CREW ROTATION",
          descriptionLong: "Awaiting cabin crew from another flight",
        },
        {
          code: 95,
          category: "Reactionary",
          alphaCode: "RC",
          descriptionShort: "95 - CREW ROTATION",
          descriptionLong:
            "Awaiting flight deck, or entire crew, from another flight",
        },
        {
          code: 96,
          category: "Reactionary",
          alphaCode: "RO",
          descriptionShort: "96 - OPERATIONS CONTROL",
          descriptionLong:
            "Re-routing, diversion, consolidation, aircraft change for reasons other than technical",
        },
        {
          code: 97,
          category: "Miscellaneous",
          alphaCode: "MI",
          descriptionShort: "97 - INDUSTRIAL ACTION WITHIN OWN AIRLINE",
          descriptionLong: "Industrial action within own airline",
        },
        {
          code: 98,
          category: "Miscellaneous",
          alphaCode: "MO",
          descriptionShort: "98 - INDUSTRIAL ACTION OUTSIDE OWN AIRLINE",
          descriptionLong:
            "Industrial action (except Air Traffic Control Services)",
        },
        {
          code: 99,
          category: "Miscellaneous",
          alphaCode: "MX",
          descriptionShort: "99 - MISCELLANEOUS",
          descriptionLong: "No suitable code; explain reason(s) in plain text",
        },
      ],
      countries: [
        "United States",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo (the Democratic Republic of the)",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Aland Islands",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      groundAssignmentTypes: [
        "Layover",
        "Travel",
        "Do Not Use",
        "Stand By",
        "Sick",
        "Special Assignment",
        "Floating Holiday",
        "Holiday",
        "Leave - Bereavement",
        "Leave - FMLA",
        "Leave - Medical",
        "Leave - Military",
        "Leave - Non-FMLA",
        "Leave - Parental",
        "Leave - Personal",
        "LOA - Jury Duty",
        "LOA - Long Term Disability",
        "LOA - Short Term Disability",
        "LOA - Workers' Compensation",
        "Quarantine - Day OFF",
        "Quarantine",
        "Training - Ground School",
        "Training - SIM",
        "Vacation",
      ],
      version: "2.0.0", //Deploy
      build: "25.11.02.1135", //Deploy
      environment: "DEV", //Deploy
    };
  },

  computed: {
    localTime() {
      return this.$store.state.localTime;
    },
    user() {
      return this.$store.getters.user;
    },

    baseBucketUrl() {
      return "https://raiden-wal-flight-times-private.s3.amazonaws.com/";
    },

    currentTimeX() {
      const ct = Date.now();
      const minutes = Math.ceil(
        (ct - new Date(this.dateFilter.start).getTime()) / 1000 / 60
      );

      if (minutes > 0) {
        return minutes / this.scale;
      } else {
        return 0;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },
  },

  methods: {
    // decodeJwt(token) {
    //   const base64Url = token.split(".")[1]; // Get payload
    //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    //   return JSON.parse(atob(base64));
    // },

    getTrainingRequirementsByJobTitle(jobTitle) {
      let trainingRequirements = [];

      //TODO complete training requirements for all job titles

      switch (jobTitle) {
        case "Captain":
        case "First Officer":
          trainingRequirements.push("Consolidation of Knowledge and Skills");
          trainingRequirements.push("Emergency Drills");
          trainingRequirements.push("Ground School");
          trainingRequirements.push("Initial Operating Experience");
          trainingRequirements.push("Line Check");
          trainingRequirements.push("Proficiency Training");
          trainingRequirements.push("Proficiency Check");
          break;
        case "Senior Flight Attendant":
        case "Flight Attendant":
          trainingRequirements.push("Emergency Drills");
          trainingRequirements.push("Ground School");

          break;

        default:
          break;
      }

      return trainingRequirements;
    },

    getDocumentsRequirementsByJobTitle(jobTitle) {
      let documentRequirements = [];

      //TODO complete document requirements for all job titles

      switch (jobTitle) {
        case "Captain":
        case "First Officer":
          documentRequirements.push("FAA Certificate");
          documentRequirements.push("Medical Certificate");
          documentRequirements.push("Passport");

          break;
        case "Senior Flight Attendant":
        case "Flight Attendant":
        case "In-Flight Mechanic":
          documentRequirements.push("Passport");
          break;

        default:
          break;
      }

      return documentRequirements;
    },

    compareExpirationDates(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    //#region ------------------------------- FORMATTERS

    isValidDate(date) {
      return date instanceof Date && !isNaN(date);
    },

    formatQuantity(number) {
      const formatter = new Intl.NumberFormat("en-US");
      return formatter.format(number);
    },

    formatDate(date, _timeZone) {
      let timeZone = "UTC";

      if (this.localTime) {
        timeZone = _timeZone;
      }

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone,
      };
      return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
    },

    formatTime(date, _timeZone) {
      let timeZone = "UTC";
      let timeZoneLabel = "UTC";

      if (this.localTime) {
        timeZone = _timeZone;
        timeZoneLabel = "LT";
      }

      const options = {
        hour: "numeric",
        hourCycle: "h23",
        minute: "numeric",
        timeZone,
      };
      return (
        new Intl.DateTimeFormat("en-US", options).format(new Date(date)) +
        ` ${timeZoneLabel}`
      );
    },

    formatDateTime(date, _timeZone) {
      let timeZone = "UTC";
      let timeZoneLabel = "UTC";

      if (this.localTime) {
        timeZone = _timeZone;
        timeZoneLabel = "LT";
      }

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
        timeZone,
      };

      return (
        new Intl.DateTimeFormat("en-US", options).format(new Date(date)) +
        ` ${timeZoneLabel}`
      );
    },

    formatMinutes(minutes) {
      const _h = Math.floor((minutes % (24 * 60)) / 60);
      const _m = Math.floor((minutes % (24 * 60)) % 60);

      const h = _h < 10 ? `0${_h}` : _h;
      const m = _m < 10 ? `0${_m}` : _m;

      return `${h}+${m}`;
    },

    formatDay(d, scale) {
      if (scale > 8) {
        return new Date(d).toUTCString().toString().substring(5, 8);
      } else {
        return new Date(d).toUTCString().substring(0, 16);
      }
    },

    formatMonth(m) {
      switch (m) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";

        case 10:
          return "October";

        case 11:
          return "November";

        case 12:
          return "December";

        default:
          return "---";
      }
    },

    formatMonthShort(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },

    formatElapsedTime(t) {
      const addLeadingZeros = (number) => {
        return String(number).padStart(2, "0");
      };

      const h = addLeadingZeros(Math.floor(t / 60));
      const m = addLeadingZeros(t % 60);

      return `${h}+${m}`;
    },

    formatName(givenName, surname, format) {
      if (format === "f") {
        return `${givenName} ${surname}`;
      } else if (format === "sub") {
        return `${surname}, ${givenName.substring(0, 1)}`;
      } else {
        return `${surname}, ${givenName}`;
      }
    },

    //#endregion

    overlaps(dayStart, dayEnd, periods) {
      if (periods.length) {
        let periodStart;
        let periodEnd;

        let count = 0;

        periods.forEach((period) => {
          periodStart = new Date(period.start).getTime();
          periodEnd = new Date(period.end).getTime();

          if (dayStart <= periodEnd && dayEnd >= periodStart) {
            count++;
          }
        });

        return count > 0;
      } else {
        return false;
      }
    },

    //#region COMPARES

    compareNames(a, b) {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    },

    //-----------------------------Special Airports
    compareAirportNameUp(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (b.name < a.name) {
        return 1;
      }
      return 0;
    },

    compareAirportNameDown(a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (b.name < a.name) {
        return -1;
      }
      return 0;
    },

    compareAirportCountryUp(a, b) {
      if (a.country < b.country) {
        return -1;
      }
      if (b.country < a.country) {
        return 1;
      }
      return 0;
    },

    compareAirportCountryDown(a, b) {
      if (a.country < b.country) {
        return 1;
      }
      if (b.country < a.country) {
        return -1;
      }
      return 0;
    },

    compareAirportIataUp(a, b) {
      if (a.iata < b.iata) {
        return -1;
      }
      if (b.iata < a.iata) {
        return 1;
      }
      return 0;
    },

    compareAirportIataDown(a, b) {
      if (a.iata < b.iata) {
        return 1;
      }
      if (b.iata < a.iata) {
        return -1;
      }
      return 0;
    },

    compareAirportIcaoUp(a, b) {
      if (a.icao < b.icao) {
        return -1;
      }
      if (b.icao < a.icao) {
        return 1;
      }
      return 0;
    },

    compareAirportIcaoDown(a, b) {
      if (a.icao < b.icao) {
        return 1;
      }
      if (b.icao < a.icao) {
        return -1;
      }
      return 0;
    },

    //-------------------------Warnings

    compareWarningTypeUp(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    compareWarningTypeDown(a, b) {
      if (a.type < b.type) {
        return 1;
      }
      if (b.type < a.type) {
        return -1;
      }
      return 0;
    },

    compareWarningMessageUp(a, b) {
      if (a.message < b.message) {
        return -1;
      }
      if (b.message < a.message) {
        return 1;
      }
      return 0;
    },

    compareWarningMessageDown(a, b) {
      if (a.message < b.message) {
        return 1;
      }
      if (b.message < a.message) {
        return -1;
      }
      return 0;
    },

    //---------------------Flights

    compareFlightAircraftRegistrationUp(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return -1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return 1;
      }
      return 0;
    },

    compareFlightAircraftRegistrationDown(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return 1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return -1;
      }
      return 0;
    },

    compareAircrafts(a, b) {
      if (a.registration < b.registration) {
        return -1;
      }
      if (b.registration < a.registration) {
        return 1;
      }
      return 0;
    },

    compareFlights(a, b) {
      if (
        new Date(a.effectiveTimeOut).getTime() <
        new Date(b.effectiveTimeOut).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.effectiveTimeOut).getTime() <
        new Date(a.effectiveTimeOut).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareVaccines(a, b) {
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
      }
      if (new Date(b.date).getTime() < new Date(a.date).getTime()) {
        return 1;
      }
      return 0;
    },

    comparePassports(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareExemptions(a, b) {
      if (a.key < b.key) {
        return -1;
      }
      if (b.key < a.key) {
        return 1;
      }
      return 0;
    },

    compareAssignments(a, b) {
      if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) {
        return -1;
      }
      if (new Date(b.startTime).getTime() < new Date(a.startTime).getTime()) {
        return 1;
      }
      return 0;
    },

    //#endregion

    getLocationLeft(time, filterStart, scale) {
      // Notes
      // time and filterStart are passed as strings
      // scale is passed as number
      const minutes =
        (new Date(time).getTime() - new Date(filterStart).getTime()) /
        1000 /
        60;
      return minutes / scale;
    },
  },
};

<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Administration
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), handleFilterEmployees()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="handleFilterEmployees()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <!-- <li class="icon" @click="showNewEmployeeDialog = true">
          <a href="#">
            <span class="tooltip">Add Employee</span>
            <span><i class="fa-solid fa-user-plus text-blue-500"></i></span>
          </a>
        </li> -->

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate ml-2"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <div v-else>
      <!-- Data Table -->
      <div class="h-screen-minus-toolbar overflow-auto">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead class="text-xs text-gray-200 bg-gray-500 sticky top-0">
            <tr>
              <th
                class="border border-gray-300 px-4 py-1.5 w-[150px] cursor-pointer"
                @click="sortArrayByKey('surname')"
              >
                Last Name
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 w-[150px] cursor-pointer"
                @click="sortArrayByKey('givenName')"
              >
                First Name
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('jobTitle')"
              >
                Job Title
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('documentsStatus')"
              >
                Documents
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('trainingRecordsStatus')"
              >
                Training Records
              </th>
              <th class="border border-gray-300 px-4 py-1.5">Security Group</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employee in employees"
              :key="employee._id"
              @click="handleSelectEmployee(employee)"
              class="bg-white border-b hover:bg-gray-50 cursor-pointer"
            >
              <td class="border border-gray-300 px-4 py1.5">
                {{ employee.surname }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ employee.givenName }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ employee.jobTitle }}
              </td>
              <td
                class="border border-gray-300 px-4 py1.5"
                :style="getDocumentsRowStyle(employee)"
              >
                {{ employee.documentsStatus }}
              </td>
              <td
                class="border border-gray-300 px-4 py1.5"
                :style="getTrainingRecordsRowStyle(employee)"
              >
                {{ employee.trainingRecordsStatus }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ employee.securityGroup.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import api from "../services/api";
import authentication from "../services/authentication";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "Administration",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      employees: [],
      allEmployees: [],
      search: "",
    };
  },

  created() {
    this.getData();
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },

  methods: {
    async getData() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "Home" });
        return;
      }

      try {
        const res = await api.get("/employees", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          params: {
            status: "Active",
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "var(--red)";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "Home" });
          }, 3000);

          return;
        }

        const _employees = res.data.employees;
        this.allEmployees = this.handleUpdateStatus([..._employees]);
        this.handleFilterEmployees();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "var(--red)";
        this.snackbarText = error;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "Home" });
        }, 3000);
      }
    },

    handleUpdateStatus(employees) {
      let employee;
      const now = new Date().getTime();
      const in30Days = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      let trainingRecordsMissingCount = 0;
      let trainingRecordsExpiredCount = 0;
      let trainingRecordsExpiringCount = 0;
      let documentsMissingCount = 0;
      let documentsExpiredCount = 0;
      let documentsExpiringCount = 0;

      let trainingRequirements = [];
      let documentsRequirements = [];

      for (let i = 0; i < employees.length; i++) {
        employee = employees[i];

        trainingRequirements = this.getTrainingRequirementsByJobTitle(
          employee.jobTitle
        );
        documentsRequirements = this.getDocumentsRequirementsByJobTitle(
          employee.jobTitle
        );

        if (trainingRequirements.length === 0) {
          employee.trainingRecordsStatus = "No Training Requirements";
        } else {
          trainingRecordsExpiredCount = 0;
          trainingRecordsExpiringCount = 0;
          trainingRecordsMissingCount = 0;

          //---------------------------------Training Records
          if (employee.trainingRecords.length === 0) {
            trainingRecordsMissingCount++;
          } else {
            let record;

            //Check if all required training records are present
            trainingRequirements.forEach((requirement) => {
              record = employee.trainingRecords.find((training) => {
                return training.name === requirement;
              });

              //If required training not found
              if (!record) {
                //If the missing required training is Proficiency Check
                if (requirement === "Proficiency Check") {
                  //Check if Proficiency Training is found
                  record = employee.trainingRecords.find((training) => {
                    return training.name === "Proficiency Training";
                  });

                  //If Proficiency Training is not found
                  if (!record) {
                    trainingRecordsMissingCount++;
                  } else {
                    //Proficiency Training was found, check if it is expired
                    if (new Date(record.validUntil).getTime() < now) {
                      trainingRecordsExpiredCount++;
                    } else if (
                      new Date(record.validUntil).getTime() < in30Days
                    ) {
                      trainingRecordsExpiringCount++;
                    }
                  }

                  //If the missing required training is Proficiency Training
                } else if (requirement === "Proficiency Training") {
                  //Check if Proficiency Check is found
                  record = employee.trainingRecords.find((training) => {
                    return training.name === "Proficiency Check";
                  });

                  //If Proficiency Check is not found
                  if (!record) {
                    trainingRecordsMissingCount++;
                  } else {
                    //Proficiency Check was found, check if it is expired
                    if (new Date(record.validUntil).getTime() < now) {
                      trainingRecordsExpiredCount++;
                    } else if (
                      new Date(record.validUntil).getTime() < in30Days
                    ) {
                      trainingRecordsExpiringCount++;
                    }
                  }
                } else {
                  //If the missing required training is not Proficiency Check or Proficiency Training, there is no alternate training
                  trainingRecordsMissingCount++;
                }
              } else {
                //If required training is found, check if it is expired (unless the training is permanent)
                if (!record.isPermanent) {
                  //Check if the training is expired
                  if (new Date(record.validUntil).getTime() < now) {
                    trainingRecordsExpiredCount++;
                  } else if (new Date(record.validUntil).getTime() < in30Days) {
                    trainingRecordsExpiringCount++;
                  }
                }
              }
            });
          }
        }

        if (trainingRecordsExpiredCount > 0) {
          employee.trainingRecordsStatus = "Expired";
        } else if (trainingRecordsExpiringCount > 0) {
          employee.trainingRecordsStatus = "Expiring in less than 30 days";
        } else {
          employee.trainingRecordsStatus = "OK";
        }

        //---------------------------------Documents

        if (documentsRequirements.length === 0) {
          employee.documentsStatus = "No Documents Requirements";
        } else {
          if (employee.documents.length === 0) {
            employee.documentsStatus = "No Documents";
          } else {
            documentsExpiredCount = 0;
            documentsExpiringCount = 0;
            documentsMissingCount = 0;

            //Check if all required documents are present
            documentsRequirements.forEach((requirement) => {
              const document = employee.documents.find((document) => {
                return document.type === requirement;
              });

              if (!document) {
                documentsMissingCount++;
              }

              if (!document.isPermanent) {
                if (new Date(document.validUntil).getTime() < now) {
                  document.status = "Expired";
                  documentsExpiredCount++;
                } else if (new Date(document.validUntil).getTime() < in30Days) {
                  document.status = "Expiring in less than 30 days";
                  documentsExpiringCount++;
                } else {
                  document.status = "OK";
                }
              } else {
                document.status = "OK";
              }
            });
          }
        }

        if (documentsExpiredCount > 0) {
          employee.documentsStatus = "Expired";
        } else if (documentsExpiringCount > 0) {
          employee.documentsStatus = "Expiring in less than 30 days";
        } else {
          const doc = employee.documents.find((doc) => doc.type === "Passport");

          if (!doc) {
            employee.documentsStatus = "OK (Domestic Only)";
          } else {
            employee.documentsStatus = "OK";
          }
        }
      }

      return employees;
    },

    getTrainingRecordsRowStyle(employee) {
      if (employee.trainingRecordsStatus === "Expired") {
        return "background-color: #FFC7CE";
      } else if (
        employee.trainingRecordsStatus === "Expiring in less than 30 days"
      ) {
        return "background-color: #FFEB9C";
      } else if (employee.trainingRecordsStatus === "OK") {
        return "background-color:  #A7F3D0";
      } else {
        return "background-color: #FFF";
      }
    },

    getDocumentsRowStyle(employee) {
      if (employee.documentsStatus === "Expired") {
        return "background-color: #FFC7CE";
      } else if (employee.documentsStatus === "Expiring in less than 30 days") {
        return "background-color: #FFEB9C";
      } else if (employee.documentsStatus === "OK") {
        return "background-color:  #A7F3D0";
      } else if (employee.documentsStatus === "OK (Domestic Only)") {
        return "background-color:  #DBEAFE";
      } else {
        return "background-color: #FFF";
      }
    },

    handleSelectEmployee(employee) {
      this.$store.commit("updateEmployee", employee);
      this.$router.push({ name: "EmployeeDetails" });
    },

    handleFilterEmployees() {
      this.employees = this.allEmployees.filter((employee) => {
        return (
          employee.surname
            .toUpperCase()
            .startsWith(this.search.toUpperCase()) ||
          employee.givenName.toUpperCase().startsWith(this.search.toUpperCase())
        );
      });
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.employees, key);

      if (sortedAsc) {
        this.employees.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.employees.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.fa-chevron-left {
  font-size: 1.3rem;
  color: white;
}
</style>
